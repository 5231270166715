<script setup lang="ts">
import { CmsBlockImageText } from '@shopware-pwa/composables-next';

const props = defineProps<{
  content: CmsBlockImageText | any;
}>();

const { getSlotContent } = useCmsBlock(props.content);

const leftContent = getSlotContent('left');
const rightContent = getSlotContent('right');
</script>
<template>
  <div class="cms-block-image-text grid grid-cols-2 gap-10">
    <CmsGenericElement :content="leftContent" />
    <CmsGenericElement :content="rightContent" />
  </div>
</template>
